import { gql } from 'graphql-tag'

export default gql`
    fragment ModuleMetricFragment on ModuleMetric {
        sys {
            id
        }
        contentType: __typename
        metric
        description
        extraInformation {
            json
        }
    }
`
