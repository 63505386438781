import { mapperModuleNavigationLink } from '../../modules/mappers'
import { mapperIcon } from '../../utils/mappers'
import { type MapperOptions } from '../../../types'

export const mapperBlockNotificationBar = (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  return {
    id: data.sys?.id || '',
    link: mapperModuleNavigationLink(data.link, options.getUrl!),
    icon: mapperIcon(data.icon)
  }
}
