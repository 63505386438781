import { gql } from 'graphql-tag'

export default gql`
  fragment GlobalDataIndustryFragment on GlobalDataIndustry {
    sys {
      id
    }
    internalName
    name
    icon
  }
`
