// columns 12, aspect ratio 16/9
const IMAGE_SIZES_COLUMNS_12_169 = {
  small: { width: 327, height: 184, aspectRatio: '16/9' },
  medium: { width: 672, height: 378, aspectRatio: '16/9' },
  large: { width: 1200, height: 675, aspectRatio: '16/9' }
}

// columns 12, aspect ratio 5/2
const IMAGE_SIZES_COLUMNS_12_52 = {
  small: { width: 327, height: 131, aspectRatio: '5/2' },
  medium: { width: 672, height: 269, aspectRatio: '5/2' },
  large: { width: 1200, height: 480, aspectRatio: '5/2' }
}

// columns 12, aspect ratio 5/2 on desktop > 16/9 on tablet and mobile
const IMAGE_SIZES_COLUMNS_12_5269 = {
  small: { width: 327, height: 184, aspectRatio: '16/9' },
  medium: { width: 672, height: 378, aspectRatio: '16/9' },
  large: { width: 1200, height: 480, aspectRatio: '5/2' }
}

// columns 12, aspect ratio 5/2 on desktop, 16/9 on mobile -- NEW GRID.

const IMAGE_SIZES_COLUMNS_12_RATIO_52_52_169 = {
  small: { width: 344, height: 193, aspectRatio: '16/9' },
  medium: { width: 690, height: 276, aspectRatio: '5/2' },
  large: { width: 1416, height: 566, aspectRatio: '5/2' }
}

// columns 6, aspect ratio 1/1 on desktop, 5/2 on tablet and mobile -- NEW GRID.

const IMAGE_SIZES_COLUMNS_6_RATIO_11_52_169 = {
  small: { width: 344, height: 193, aspectRatio: '16/9' },
  medium: { width: 690, height: 276, aspectRatio: '5/2' },
  large: { width: 576, height: 576, aspectRatio: '1/1' }
}

// columns 12, aspect ration 2/1 -- NEW GRID.
const IMAGE_SIZES_COLUMNS_12_21 = {
  small: { width: 392, height: 196, aspectRatio: '2/1' },
  medium: { width: 786, height: 393, aspectRatio: '2/1' },
  large: { width: 1608, height: 804, aspectRatio: '2/1' }
}

const IMAGE_SIZES_COLUMNS_10_169 = {
  small: { width: 327, height: 184, aspectRatio: '16/9' },
  medium: { width: 672, height: 378, aspectRatio: '16/9' },
  large: { width: 990, height: 556, aspectRatio: '16/9' }
}

// columns 10, aspect ration 2/1 -- NEW GRID.
const IMAGE_SIZES_COLUMNS_10_21 = {
  small: { width: 344, height: 127, aspectRatio: '2/1' },
  medium: { width: 690, height: 345, aspectRatio: '2/1' },
  large: { width: 1176, height: 588, aspectRatio: '2/1' }
}

// columns 6, aspect ratio 1/1
const IMAGE_SIZES_COLUMNS_6_11 = {
  small: { width: 327, height: 327, aspectRatio: '1/1' },
  medium: { width: 312, height: 312, aspectRatio: '1/1' },
  large: { width: 576, height: 576, aspectRatio: '1/1' }
}
// columns 6, aspect ratio 4/3
const IMAGE_SIZES_COLUMNS_6_43 = {
  small: { width: 436, height: 327, aspectRatio: '4/3' },
  medium: { width: 416, height: 312, aspectRatio: '4/3' },
  large: { width: 768, height: 576, aspectRatio: '4/3' }
}
// columns 6, aspect ratio 3/4
const IMAGE_SIZES_COLUMNS_6_34 = {
  small: { width: 327, height: 436, aspectRatio: '3/4' },
  medium: { width: 312, height: 416, aspectRatio: '3/4' },
  large: { width: 576, height: 768, aspectRatio: '3/4' }
}
// columns 5, aspect ratio 3/4
const IMAGE_SIZES_COLUMNS_5_34 = {
  small: { width: 327, height: 436, aspectRatio: '3/4' },
  medium: { width: 312, height: 416, aspectRatio: '3/4' },
  large: { width: 472, height: 629, aspectRatio: '3/4' }
}
// columns 4, aspect ratio 16/9
const IMAGE_SIZES_COLUMNS_4_169 = {
  small: { width: 336, height: 189, aspectRatio: '16/9' },
  medium: { width: 320, height: 180, aspectRatio: '16/9' },
  large: { width: 368, height: 207, aspectRatio: '16/9' }
}
// columns 3, aspect ratio 1/1
const IMAGE_SIZES_COLUMNS_3_11 = {
  small: { width: 327, height: 327, aspectRatio: '1/1' },
  medium: { width: 312, height: 312, aspectRatio: '1/1' },
  large: { width: 264, height: 264, aspectRatio: '1/1' }
}

const IMAGE_SIZES_COLUMNS_5_11 = {
  small: { width: 312, height: 312, aspectRatio: '1/1' },
  medium: { width: 486, height: 486, aspectRatio: '1/1' },
  large: { width: 680, height: 680, aspectRatio: '1/1' }
}

const IMAGE_SIZES_COLUMNS_4_34 = {
  small: { width: 392, height: 294, aspectRatio: '4/3' },
  medium: { width: 552, height: 736, aspectRatio: '3/4' },
  large: { width: 552, height: 736, aspectRatio: '3/4' }
}

// columns 3, aspect ratio 1/1
const IMAGE_SIZES_TERMINAL_GRID = {
  small: { width: 312, height: 416, aspectRatio: '3/4' },
  medium: { width: 312, height: 416, aspectRatio: '3/4' },
  large: { width: 264, height: 352, aspectRatio: '3/4' }
}

// columns large: 4, medium: 6, small: 2, aspect ratio 16/9
const IMAGE_SIZES_RELATED_ARTICLES = {
  small: { width: 303, height: 170, aspectRatio: '16/9' },
  medium: { width: 407, height: 229, aspectRatio: '16/9' },
  large: { width: 484, height: 272, aspectRatio: '16/9' }
}

const IMAGE_SIZES_INDEX_REPORTS = {
  small: { width: 533, height: 400, aspectRatio: '4/3' },
  medium: { width: 440, height: 550, aspectRatio: '4/5' },
  large: { width: 336, height: 420, aspectRatio: '4/5' }
}

const IMAGE_SIZES_EVENT_SPEAKERS = {
  small: { width: 327, height: 436, aspectRatio: '3/4' },
  medium: { width: 312, height: 416, aspectRatio: '3/4' },
  large: { width: 368, height: 490, aspectRatio: '3/4' }
}

export default {
  columns12_169: IMAGE_SIZES_COLUMNS_12_169,
  columns12_52: IMAGE_SIZES_COLUMNS_12_52,
  columns12_5269: IMAGE_SIZES_COLUMNS_12_5269,
  columns12_ratio_52_52_169: IMAGE_SIZES_COLUMNS_12_RATIO_52_52_169,
  columns12_21: IMAGE_SIZES_COLUMNS_12_21,
  columns10_21: IMAGE_SIZES_COLUMNS_10_21,
  columns10_169: IMAGE_SIZES_COLUMNS_10_169,
  columns6_11: IMAGE_SIZES_COLUMNS_6_11,
  columns6_43: IMAGE_SIZES_COLUMNS_6_43,
  columns6_34: IMAGE_SIZES_COLUMNS_6_34,
  columns6_ratio_11_52_169: IMAGE_SIZES_COLUMNS_6_RATIO_11_52_169,
  columns5_34: IMAGE_SIZES_COLUMNS_5_34,
  columns5_11: IMAGE_SIZES_COLUMNS_5_11,
  columns4_169: IMAGE_SIZES_COLUMNS_4_169,
  columns3_11: IMAGE_SIZES_COLUMNS_3_11,
  terminals_grid: IMAGE_SIZES_TERMINAL_GRID,
  related_articles: IMAGE_SIZES_RELATED_ARTICLES,
  index_reports: IMAGE_SIZES_INDEX_REPORTS,
  event_speakers: IMAGE_SIZES_EVENT_SPEAKERS,
  columns4_34: IMAGE_SIZES_COLUMNS_4_34
}
