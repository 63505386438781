import imgModule from '../../constants/imageSizes.js'
// when the import happens in the server it is processed as a module, and we need to retrieve the .default
const imageSizes = imgModule?.default || imgModule

const heroImageSizes = Object.values(imageSizes.columns5_34)
const heroVerticalImageSizes = Object.values(imageSizes.columns12_169)

// TODO: fix the image sizes for contentBlock1
const PageProductUnifiedCommerce = {
  contentType: 'PageProductUnifiedCommerce',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    {
      id: 'logoBar1',
      name: 'Logo bar 1',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    {
      id: 'callToAction1',
      name: 'Call to action 1',
      type: 'BlockCallToAction'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockMediaCardGroup',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'callToAction2',
      name: 'Call to action 2',
      type: 'Array',
      subType: 'BlockCallToAction'
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockQuote'
    },
    {
      id: 'contentBlock5',
      name: 'Content block 5',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43) }
    },
    {
      id: 'logoBar2',
      name: 'Logo bar 2',
      type: 'BlockLogoBar'
    },
    {
      id: 'bottomPageCta',
      name: 'Bottom page CTA',
      type: 'BlockCallToAction'
    },
    {
      id: 'bottomPageDoubleCta',
      name: 'Bottom page double CTA',
      type: 'Array',
      subType: 'BlockCallToAction'
    }
  ]
}

const PageProductPayouts = {
  contentType: 'PageProductPayouts',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'media',
      name: 'Media',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_52) }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockQuote'
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent'
    },
    {
      id: 'contentBlock5',
      name: 'Content block 5',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'faqs',
      name: 'FAQ',
      type: 'BlockFaqGroup'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}
// TODO - Uncomment when content is ready

const PageProductPlatformPayments = {
  contentType: 'PageProductPlatformPayments',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockContent',
      imageOptions: {
        resize: true,
        sizes: [
          { width: 327, height: 245, aspectRatio: '4/3' },
          { width: 312, height: 234, aspectRatio: '4/3' },
          { width: 576, height: 432, aspectRatio: '4/3' }
        ]
      }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43) }
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_52) }
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'blockQuote'
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    {
      id: 'faq',
      name: 'FAQ',
      type: 'BlockFaqGroup'
    },
    {
      id: 'callToAction',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

const PageProductPosPayments = {
  contentType: 'PageProductPosPayments',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns3_11) }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43) }
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'blockQuote'
    },
    {
      id: 'logoBar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'media',
      name: 'Media',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269) }
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock5',
      name: 'Content block 5',
      type: 'BlockGroup',
      subType: 'BlockTabContentLazy',
      lazy: true,
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns4_169) }
    },
    {
      id: 'contentBlock6',
      name: 'Content block 6',
      type: 'BlockLogoBar'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

const PageProductDynamicCurrencyConversion = {
  contentType: 'PageProductDynamicCurrencyConversion',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroVerticalImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

const PageProductWebPayments = {
  contentType: 'PageProductWebPayments',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroVerticalImageSizes }
    },
    {
      id: 'logoBar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43) }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43) }
    },
    {
      id: 'usps',
      name: 'Usps',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    // TODO: Remove block in a script
    // {
    //   id: 'contentBlock3',
    //   name: 'Content block 3',
    //   type: 'BlockGroup',
    //   subType: 'BlockContent'
    // },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent'
    },
    {
      id: 'contentBlock5',
      name: 'Content block 5',
      type: 'BlockLogoBar'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}
const PageProductInApp = {
  contentType: 'PageProductInApp',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroVerticalImageSizes }
    },
    {
      id: 'logoBar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269) }
    },
    {
      id: 'usps',
      name: 'Usps',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43) }
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent',
      imageOptions: {
        resize: true,
        // 5/2 size on all devices, we don't currently have it in the imageSizes as we don't have other use cases
        sizes: Object.values({
          small: { width: 327, height: 130, aspectRatio: '5/2' },
          medium: { width: 672, height: 268, aspectRatio: '5/2' },
          large: { width: 1200, height: 480, aspectRatio: '5/2' }
        })
      }
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}
const PageProductPlugins = {
  contentType: 'PageProductPlugins',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroVerticalImageSizes }
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269) }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'quote1',
      name: 'Quote 1',
      type: 'BlockQuote'
    },
    {
      id: 'usps',
      name: 'Usps',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_52) }
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_52) }
    },
    {
      id: 'quote2',
      name: 'Quote 2',
      type: 'BlockQuote'
    },
    {
      id: 'logoBar',
      name: 'Logo Bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}
const PageProductPayByLink = {
  contentType: 'PageProductPayByLink',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroVerticalImageSizes }
    },
    {
      id: 'heroUsps',
      name: 'Hero usps',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'usps',
      name: 'Usps',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'BlockTabContentLazy',
      imageOptions: {
        resize: true,
        sizes: [
          { width: 327, height: 327, aspectRatio: '1/1' },
          { width: 312, height: 312, aspectRatio: '1/1' },
          { width: 264, height: 264, aspectRatio: '1/1' }]
      },
      lazy: true
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}
const PageProductGlobalPaymentProcessing = {
  contentType: 'PageProductGlobalPaymentProcessing',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroVerticalImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'media',
      name: 'Media',
      type: ['ModuleVideo', 'ModuleInternalVideo']
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_169) }
    },
    {
      id: 'usps',
      name: 'Usps',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'quote1',
      name: 'Quote 1',
      type: 'BlockQuote'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43) }
    },
    {
      id: 'quote2',
      name: 'Quote 2',
      type: 'BlockQuote'
    },
    {
      id: 'contentBlock5',
      name: 'Content block 5',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'contentBlock6',
      name: 'Content block 6',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    {
      id: 'contentBlock7',
      name: 'Content block 7',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}
const PageProductOnlinePayments = {
  contentType: 'PageProductOnlinePayments',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_169) }
    },
    {
      id: 'logoBar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: {
        resize: true,
        sizes: Object.values({
          small: { width: 327, height: 70 },
          medium: { width: 672, height: 150 },
          large: { width: 1200, height: 158 }
        })
      }
    },

    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockGroup',
      subType: 'BlockTabContentLazy',
      lazy: true
    },
    {
      id: 'contentBlock5',
      name: 'Content block 5',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269), lazy: true }
    },
    {
      id: 'contentBlock6',
      name: 'Content block 6',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11), lazy: true }
    },
    {
      id: 'callToAction',
      name: 'Call to action',
      type: 'BlockCallToAction'
    },
    {
      id: 'contentBlock7',
      name: 'Content block 7',
      type: 'BlockContent',
      mageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11), lazy: true }
    },
    {
      id: 'contentBlock8',
      name: 'Content block 8',
      type: 'BlockCallToAction'
    }
  ]
}

const PageProductAcceptPayments = {
  contentType: 'PageProductAcceptPayments',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'logoBar1',
      name: 'Logo bar 1',
      type: 'BlockLogoBar'
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockTabContentLazy',
      lazy: true,
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'callOut1',
      name: 'Callout 1',
      type: 'BlockCallToAction'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: {
        resize: true,
        sizes: Object.values({
          small: { width: 327, height: 183.94 },
          medium: { width: 672, height: 378 },
          large: { width: 1200, height: 480 }
        })
      }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockGroup'
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockQuote'
    },
    {
      id: 'quoteMedia',
      name: 'Quote media',
      type: 'ModuleMedia'
    },
    {
      id: 'image',
      name: 'Module media',
      type: 'ModuleMedia'
    },
    {
      id: 'logoBar2',
      name: 'Logo bar 2',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockGroup'
    },

    {
      id: 'contentBlock5',
      name: 'Content block 5',
      type: 'BlockGroup'
    },
    {
      id: 'callOut2',
      name: 'Callout 2',
      type: 'BlockCallToAction'
    },
    {
      id: 'cta',
      name: 'Bottom page CTA',
      type: 'BlockCallToAction'
    },
    {
      id: 'faq',
      name: 'FAQ',
      type: 'BlockFaqGroup'
    }
  ]
}

const PageProductDataConnect = {
  contentType: 'PageProductDataConnect',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'media',
      name: 'Media',
      type: ['ModuleVideo', 'ModuleInternalVideo']
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockQuote'
    },
    {
      id: 'logoBar',
      name: 'Logo bar ',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'image',
      name: 'Module media',
      type: 'ModuleMedia'
    },
    {
      id: 'faqs',
      name: 'FAQ',
      type: 'BlockFaqGroup'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

export default {
  PageProductPayouts,
  PageProductUnifiedCommerce,
  PageProductPlatformPayments,
  PageProductDynamicCurrencyConversion,
  PageProductGlobalPaymentProcessing,
  PageProductInApp,
  PageProductOnlinePayments,
  PageProductPayByLink,
  PageProductPlugins,
  PageProductPosPayments,
  PageProductWebPayments,
  PageProductAcceptPayments,
  PageProductDataConnect
}
